<template>
  <div
    class="btn d-flex align-items-center justify-content-center cursor-pointer"
    :class="bg"
    @click="handle"
    :style="{ width: size * padding + 'px', height: size * padding + 'px' }"
  >
    <BaseIcon :name="name" :width="size" :height="size" :color="stroke" class="d-flex" v-if="!loading"></BaseIcon>
    <BaseIcon
      name="loader"
      :width="size"
      :height="size"
      :color="stroke"
      class="d-flex"
      :class="spinnerRotate"
      v-if="loading"
    ></BaseIcon>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: { BaseIcon },
  name: "ButtonCircle",
  props: {
    name: {
      type: String,
      default: "box",
    },
    size: {
      type: String,
      default: "16",
    },
    color: {
      type: String,
      default: null,
    },
    colorClass: {
      type: String,
      default: "text-primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    propagation: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    bg: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "btn-circle-light";
      } else {
        return "btn-circle-dark";
      }
    },
    stroke: function () {
      if (this.color) {
        return this.color;
      }

      if (this.name === "x") {
        return "#dc3545";
      }
      if (this.name === "download") {
        return "#28a745";
      }
      if (this.name === "save") {
        return "#dc3545";
      }
      if (this.name === "trash-2") {
        return "#dc3545";
      }
      if (this.name === "lock") {
        return "#dc3545";
      }
      if (this.name === "check-circle") {
        return "#28a745";
      }
      if (!this.$store.getters["colors/darkMode"]) {
        return "#007bff";
      } else {
        return "#007bff";
      }
    },
    spinnerRotate() {
      return this.loading ? "icon-rotate" : "";
    },
  },
  methods: {
    handle: function (event) {
      if (!this.propagation) {
        event.stopPropagation();
      }
      this.$emit("click");
    },
  },
};
</script>
<style lang="css">
.btn-circle-light {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0;
  border: 0;
  outline: 0;
}

.btn-circle-light:hover {
  background-color: #eeeff0;
}

.btn-circle-dark {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: none;
}

.btn-circle-dark:hover {
  background-color: #1d2024;
}
</style>
