<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
          <svg viewBox="0 0 24 24" id="icon--bold" style="width: 14px; height: 14px">
            <path
              d="M17.194 10.962A6.271 6.271 0 0012.844.248H4.3a1.25 1.25 0 000 2.5h1.013a.25.25 0 01.25.25V21a.25.25 0 01-.25.25H4.3a1.25 1.25 0 100 2.5h9.963a6.742 6.742 0 002.93-12.786zm-4.35-8.214a3.762 3.762 0 010 7.523H8.313a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25zm1.42 18.5H8.313a.25.25 0 01-.25-.25v-7.977a.25.25 0 01.25-.25h5.951a4.239 4.239 0 010 8.477z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <svg viewBox="0 0 24 24" id="icon--italic" style="width: 14px; height: 14px">
            <path
              d="M22.5.248h-7.637a1.25 1.25 0 000 2.5h1.086a.25.25 0 01.211.384L4.78 21.017a.5.5 0 01-.422.231H1.5a1.25 1.25 0 000 2.5h7.637a1.25 1.25 0 000-2.5H8.051a.25.25 0 01-.211-.384L19.22 2.98a.5.5 0 01.422-.232H22.5a1.25 1.25 0 000-2.5z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
          <svg viewBox="0 0 24 24" id="icon--strike" style="width: 14px; height: 14px">
            <path
              d="M23.75 12.952A1.25 1.25 0 0022.5 11.7h-8.936a.492.492 0 01-.282-.09c-.722-.513-1.482-.981-2.218-1.432-2.8-1.715-4.5-2.9-4.5-4.863 0-2.235 2.207-2.569 3.523-2.569a4.54 4.54 0 013.081.764 2.662 2.662 0 01.447 1.99v.3a1.25 1.25 0 102.5 0v-.268a4.887 4.887 0 00-1.165-3.777C13.949.741 12.359.248 10.091.248c-3.658 0-6.023 1.989-6.023 5.069 0 2.773 1.892 4.512 4 5.927a.25.25 0 01-.139.458H1.5a1.25 1.25 0 000 2.5h10.977a.251.251 0 01.159.058 4.339 4.339 0 011.932 3.466c0 3.268-3.426 3.522-4.477 3.522-1.814 0-3.139-.405-3.834-1.173a3.394 3.394 0 01-.65-2.7 1.25 1.25 0 00-2.488-.246A5.76 5.76 0 004.4 21.753c1.2 1.324 3.114 2 5.688 2 4.174 0 6.977-2.42 6.977-6.022a6.059 6.059 0 00-.849-3.147.25.25 0 01.216-.377H22.5a1.25 1.25 0 001.25-1.255z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
          <svg viewBox="0 0 24 24" id="icon--underline" style="width: 14px; height: 14px">
            <path
              d="M22.5 21.248h-21a1.25 1.25 0 000 2.5h21a1.25 1.25 0 000-2.5zM1.978 2.748h1.363a.25.25 0 01.25.25v8.523a8.409 8.409 0 0016.818 0V3a.25.25 0 01.25-.25h1.363a1.25 1.25 0 000-2.5H16.3a1.25 1.25 0 000 2.5h1.363a.25.25 0 01.25.25v8.523a5.909 5.909 0 01-11.818 0V3a.25.25 0 01.25-.25H7.7a1.25 1.25 0 100-2.5H1.978a1.25 1.25 0 000 2.5z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
          <svg viewBox="0 0 24 24" id="icon--code" style="width: 14px; height: 14px">
            <path
              d="M9.147 21.552a1.244 1.244 0 01-.895-.378L.84 13.561a2.257 2.257 0 010-3.125l7.412-7.613a1.25 1.25 0 011.791 1.744l-6.9 7.083a.5.5 0 000 .7l6.9 7.082a1.25 1.25 0 01-.9 2.122zm5.707 0a1.25 1.25 0 01-.9-2.122l6.9-7.083a.5.5 0 000-.7l-6.9-7.082a1.25 1.25 0 011.791-1.744l7.411 7.612a2.257 2.257 0 010 3.125l-7.412 7.614a1.244 1.244 0 01-.89.38zm6.514-9.373z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
          <svg viewBox="0 0 24 24" id="icon--paragraph" style="width: 14px; height: 14px">
            <path
              d="M22.5.248H7.228a6.977 6.977 0 100 13.954h2.318a.25.25 0 01.25.25V22.5a1.25 1.25 0 002.5 0V3a.25.25 0 01.25-.25h3.682a.25.25 0 01.25.25v19.5a1.25 1.25 0 002.5 0V3a.249.249 0 01.25-.25H22.5a1.25 1.25 0 000-2.5zM9.8 11.452a.25.25 0 01-.25.25H7.228a4.477 4.477 0 110-8.954h2.318A.25.25 0 019.8 3z"
            />
          </svg>
        </button>
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
          <svg viewBox="0 0 24 24" id="icon--ul" style="width: 14px; height: 14px">
            <circle cx="2.5" cy="3.998" r="2.5" />
            <path d="M8.5 5H23a1 1 0 000-2H8.5a1 1 0 000 2z" />
            <circle cx="2.5" cy="11.998" r="2.5" />
            <path d="M23 11H8.5a1 1 0 000 2H23a1 1 0 000-2z" />
            <circle cx="2.5" cy="19.998" r="2.5" />
            <path d="M23 19H8.5a1 1 0 000 2H23a1 1 0 000-2z" />
          </svg>
        </button>
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <svg viewBox="0 0 24 24" id="icon--ol" style="width: 14px; height: 14px">
            <path
              d="M7.75 4.5h15a1 1 0 000-2h-15a1 1 0 000 2zm15 6.5h-15a1 1 0 100 2h15a1 1 0 000-2zm0 8.5h-15a1 1 0 000 2h15a1 1 0 000-2zM2.212 17.248a2 2 0 00-1.933 1.484.75.75 0 101.45.386.5.5 0 11.483.63.75.75 0 100 1.5.5.5 0 11-.482.635.75.75 0 10-1.445.4 2 2 0 103.589-1.648.251.251 0 010-.278 2 2 0 00-1.662-3.111zm2.038-6.5a2 2 0 00-4 0 .75.75 0 001.5 0 .5.5 0 011 0 1.031 1.031 0 01-.227.645L.414 14.029A.75.75 0 001 15.248h2.5a.75.75 0 000-1.5h-.419a.249.249 0 01-.195-.406L3.7 12.33a2.544 2.544 0 00.55-1.582zM4 5.248h-.25A.25.25 0 013.5 5V1.623A1.377 1.377 0 002.125.248H1.5a.75.75 0 000 1.5h.25A.25.25 0 012 2v3a.25.25 0 01-.25.25H1.5a.75.75 0 000 1.5H4a.75.75 0 000-1.5z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
          <svg viewBox="0 0 24 24" id="icon--quote" style="width: 14px; height: 14px">
            <path
              d="M18.559 3.932a4.942 4.942 0 100 9.883 4.609 4.609 0 001.115-.141.25.25 0 01.276.368 6.83 6.83 0 01-5.878 3.523 1.25 1.25 0 000 2.5 9.71 9.71 0 009.428-9.95V8.873a4.947 4.947 0 00-4.941-4.941zm-12.323 0a4.942 4.942 0 000 9.883 4.6 4.6 0 001.115-.141.25.25 0 01.277.368 6.83 6.83 0 01-5.878 3.523 1.25 1.25 0 000 2.5 9.711 9.711 0 009.428-9.95V8.873a4.947 4.947 0 00-4.942-4.941z"
            />
          </svg>
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block">
          <svg viewBox="0 0 24 24" id="icon--code" style="width: 14px; height: 14px">
            <path
              d="M9.147 21.552a1.244 1.244 0 01-.895-.378L.84 13.561a2.257 2.257 0 010-3.125l7.412-7.613a1.25 1.25 0 011.791 1.744l-6.9 7.083a.5.5 0 000 .7l6.9 7.082a1.25 1.25 0 01-.9 2.122zm5.707 0a1.25 1.25 0 01-.9-2.122l6.9-7.083a.5.5 0 000-.7l-6.9-7.082a1.25 1.25 0 011.791-1.744l7.411 7.612a2.257 2.257 0 010 3.125l-7.412 7.614a1.244 1.244 0 01-.89.38zm6.514-9.373z"
            />
          </svg>
        </button>
        <button class="menubar__button" @click="commands.horizontal_rule">
          <svg viewBox="0 0 24 24" id="icon--hr" style="width: 14px; height: 14px">
            <path d="M5 13a1 1 0 010-2h14a1 1 0 010 2H5z" />
          </svg>
        </button>
        <button class="menubar__button" @click="commands.undo">
          <svg viewBox="0 0 24 24" id="icon--undo" style="width: 14px; height: 14px">
            <path
              d="M17.786 3.77a12.542 12.542 0 00-12.965-.865.249.249 0 01-.292-.045L1.937.269A.507.507 0 001.392.16a.5.5 0 00-.308.462v6.7a.5.5 0 00.5.5h6.7a.5.5 0 00.354-.854L6.783 5.115a.253.253 0 01-.068-.228.249.249 0 01.152-.181 10 10 0 019.466 1.1 9.759 9.759 0 01.094 15.809 1.25 1.25 0 001.473 2.016 12.122 12.122 0 005.013-9.961 12.125 12.125 0 00-5.127-9.9z"
            />
          </svg>
        </button>
        <button class="menubar__button" @click="commands.redo">
          <svg viewBox="0 0 24 24" id="icon--redo" style="width: 14px; height: 14px">
            <path
              d="M22.608.161a.5.5 0 00-.545.108L19.472 2.86a.25.25 0 01-.292.045 12.537 12.537 0 00-12.966.865A12.259 12.259 0 006.1 23.632a1.25 1.25 0 001.476-2.018 9.759 9.759 0 01.091-15.809 10 10 0 019.466-1.1.25.25 0 01.084.409l-1.85 1.85a.5.5 0 00.354.853h6.7a.5.5 0 00.5-.5V.623a.5.5 0 00-.313-.462z"
            />
          </svg>
        </button>
        <button class="menubar__button" @click="getImage">
          <svg viewBox="0 0 24 24" id="icon--image" style="width: 14px; height: 14px">
            <circle cx="9.75" cy="6.247" r="2.25" />
            <path
              d="M16.916 8.71A1.027 1.027 0 0016 8.158a1.007 1.007 0 00-.892.586l-1.558 3.434a.249.249 0 01-.422.053l-.82-1.024a1 1 0 00-.813-.376 1.007 1.007 0 00-.787.426L7.59 15.71a.5.5 0 00.41.79h12a.5.5 0 00.425-.237.5.5 0 00.022-.486z"
            />
            <path
              d="M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z"
            />
            <path d="M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z" />
          </svg>
        </button>
      </div>
    </editor-menu-bar>
    <editor-content ref="__editor" class="editor__content p-3" :editor="editor" />
  </div>
</template>

<script>
//import Icon from "@/components/icon/Icon.vue";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import files from "@/mixins/files.js";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  mixins: [files],
  methods: {
    getImage: function () {
      let e = this.editor;
      this.convertImageToBase64(function (b64) {
        e.setContent(e.getHTML() + "<img src=" + b64 + ">"); // CALLBACK
      });
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Image(),
      ],
      //content: this.value,
      onUpdate: ({ getHTML }) => {
        this.$emit("input", getHTML());
      },
    });
    this.editor.setContent(this.value);
  },
  props: ["value"],
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style>
.has-focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 3px rgba(62, 164, 255, 0.9);
  box-shadow: 0 0 0 3px rgba(62, 164, 255, 0.9);
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
:focus {
  outline: none;
}

blockquote,
h1,
h2,
h3,
ol,
p,
pre,
ul {
  margin: 1rem 0;
}
blockquote:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
ol:first-child,
p:first-child,
pre:first-child,
ul:first-child {
  margin-top: 0;
}
blockquote:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
ol:last-child,
p:last-child,
pre:last-child,
ul:last-child {
  margin-bottom: 0;
}
h1,
h2,
h3 {
  line-height: 1.3;
}
.button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.message {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-style: italic;
}
.editor {
  position: relative;
  max-width: 100%;
}
.editor__content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  min-height: 300px;
}
.editor__content * {
  caret-color: currentColor;
}
.editor__content pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 0.8rem;
  overflow-x: auto;
}
.editor__content pre code {
  display: block;
}
.editor__content p {
  text-align: justify;
}
.editor__content p code {
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
}
.editor__content ol,
.editor__content ul {
  padding-left: 1rem;
}
.editor__content li > ol,
.editor__content li > p,
.editor__content li > ul {
  margin: 0;
}
.editor__content a {
  color: inherit;
}
.editor__content blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  padding-left: 0.8rem;
  font-style: italic;
}
.editor__content blockquote p {
  margin: 0;
}
.editor__content img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 3px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.editor__content table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.editor__content table td,
.editor__content table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.editor__content table td > *,
.editor__content table th > * {
  margin-bottom: 0;
}
.editor__content table th {
  font-weight: 700;
  text-align: left;
}
.editor__content table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.editor__content table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}
.editor__content .tableWrapper {
  margin: 1em 0;
  overflow-x: auto;
}
.editor__content .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.menubar {
  margin-bottom: 1rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}
.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}
.menubar.is-focused {
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.2s, opacity 0.2s;
  transition: visibility 0.2s, opacity 0.2s;
}
.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menubar__button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.menubar__button.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.menubar span.menubar__button {
  font-size: 13.3333px;
}
.menububble {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}
.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.menububble__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff;
}
.icon[data-v-2b9db09d] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  top: -0.05rem;
  fill: currentColor;
}
.icon__svg[data-v-2b9db09d] {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
}
.icon[data-v-2b9db09d]:first-child {
  margin-left: 0;
}
.icon[data-v-2b9db09d]:last-child {
  margin-right: 0;
}
.icon use > svg circle[data-v-2b9db09d],
.icon use > svg g[data-v-2b9db09d],
.icon use > svg path[data-v-2b9db09d],
.icon use > svg rect[data-v-2b9db09d],
body > svg circle[data-v-2b9db09d],
body > svg g[data-v-2b9db09d],
body > svg path[data-v-2b9db09d],
body > svg rect[data-v-2b9db09d],
symbol circle[data-v-2b9db09d],
symbol g[data-v-2b9db09d],
symbol path[data-v-2b9db09d],
symbol rect[data-v-2b9db09d] {
  fill: currentColor;
  stroke: none;
}
.icon use > svg [d="M0 0h24v24H0z"][data-v-2b9db09d],
body > svg [d="M0 0h24v24H0z"][data-v-2b9db09d],
symbol [d="M0 0h24v24H0z"][data-v-2b9db09d] {
  display: none;
}
.navigation[data-v-802d4490] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem;
  background-color: #000;
  color: #fff;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.navigation__logo[data-v-802d4490] {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0 0.5rem 0 0;
}
.navigation__icon[data-v-802d4490] {
  width: 1.5rem;
  height: 1.5rem;
}
.navigation__count[data-v-802d4490] {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.3rem;
}
.navigation__link[data-v-802d4490] {
  display: inline-block;
  color: hsla(0, 0%, 100%, 0.5);
  text-decoration: none;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.1rem 0.5rem;
  border-radius: 3px;
}
.navigation__link[data-v-802d4490]:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.navigation__github-link[data-v-802d4490] {
  margin-left: 0.5rem;
}
.hero[data-v-5a80d244] {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 3rem 1rem;
}
.hero__inner[data-v-5a80d244] {
  margin: 0 auto;
  max-width: 30rem;
}
.hero__logo[data-v-5a80d244] {
  width: 4rem;
  height: 4rem;
}
.hero__logo path[data-v-5a80d244] {
  fill: #fff;
}
.subnavigation[data-v-460c8f74] {
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
}
@media (min-width: 600px) {
  .subnavigation[data-v-460c8f74] {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}
.subnavigation__link[data-v-460c8f74] {
  display: inline-block;
  color: hsla(0, 0%, 100%, 0.5);
  text-decoration: none;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.1rem 0.5rem;
  border-radius: 3px;
}
.subnavigation__link[data-v-460c8f74]:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.subnavigation__link.is-exact-active[data-v-460c8f74] {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.2);
}
.ad[data-v-d8041bf2] {
  display: block;
  padding: 1rem;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  margin: 3rem auto 0;
  width: 15rem;
}
@media (min-width: 1020px) {
  .ad[data-v-d8041bf2] {
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 0;
  }
}
.ad__image[data-v-d8041bf2] {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
}
.ad[data-v-d8041bf2]:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.ad:hover .ad__image[data-v-d8041bf2] {
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 5px 20px 0 rgba(0, 0, 0, 0.06),
    0 8px 40px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 5px 20px 0 rgba(0, 0, 0, 0.06), 0 8px 40px 0 rgba(0, 0, 0, 0.04);
}
.page__content {
  padding: 4rem 1rem;
}
.page__footer {
  text-align: center;
  margin-bottom: 2rem;
}
.page__source-link {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}
.ProseMirror {
  position: relative;
  word-wrap: break-word;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}
.ProseMirror,
.ProseMirror pre {
  white-space: pre-wrap;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}
.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid #000;
  -webkit-animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}
@-webkit-keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
.ProseMirror-hideselection ::selection {
  background: transparent;
}
.ProseMirror-hideselection ::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection * {
  caret-color: transparent;
}
.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
.editor__content {
  /*background-color: white;*/
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
}

img.ProseMirror-selectednode {
  border: 0.2rem solid #007bff;
  border-radius: 0.25rem !important;
}
</style>
