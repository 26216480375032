<template>
  <div class="w-100">
    <div class="float-label-area mt-4">
      <textarea
        :id="id"
        v-bind="$attrs"
        autocomplete="off"
        :class="validationClass + ' ' + textClass"
        :placeholder="text"
        :aria-label="text"
        :value="value"
        rows="1"
        @change="($event) => mixin_autoResize_resize($event)"
        @input="($event) => $emit('input', $event.target.value)"
      />
      <label class="label d-block text-truncate w-100 cursor-text" :class="labelClass" :for="id">{{ text }}</label>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors.length > 0">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
import mixinAutoResize from "@/mixins/autoResize.js";

export default {
  name: "BaseInputArea",
  components: {},
  inheritAttrs: false,
  mixins: [mixinAutoResize],
  data() {
    return {
      id: null,
    };
  },
  props: {
    text: {
      type: String,
      default: "label",
    },
    value: {
      type: String,
      default: null,
    },
    errors: {
      type: [Object, Array, String, Number],
      default: () => {
        return {};
      },
    },
  },
  computed: {
    validationClass: function () {
      if (this.errors) {
        return "invalid";
      } else {
        return "valid";
      }
    },

    textClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-dark";
      } else {
        return "text-white";
      }
    },

    labelClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },
  },
};
</script>
<style lang="css">
textarea {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  padding: 0px 0px 4px;
  outline: 0;
  transition: height 0.3s ease;
  overflow: hidden;
  min-height: 30px;
}

/* LABEL TRANSLATION */
.float-label-area {
  position: relative;
}

/* LABEL TRANSLATION */
.float-label-area label {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 12.5px;
}

/* LABEL TRANSLATION */
.float-label-area textarea:not(:placeholder-shown) + label {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 1;
}

/* VALID COLOR - WHITE MODE */
.float-label-area textarea:valid + label.label-dark {
  color: #007bff;
}
/* VALID COLOR - DARK MODE */
.float-label-area textarea:valid + label.label-white {
  color: #007bff;
}
/* FOCUS COLOR - WHITE MODE */
.float-label-area textarea:focus + label.label-dark {
  color: #6f42c1;
}
/* FOCUS COLOR - DARK MODE */
.float-label-area textarea:focus + label.label-white {
  color: #eb9d00;
}
/* BORDER BOTTOM COLOR - WHITE MODE */
.float-label-area textarea:focus.text-dark {
  border-bottom: solid 1px #6f42c1 !important;
}
/* BORDER BOTTOM COLOR - DARK MODE */
.float-label-area textarea:focus.text-white {
  border-bottom: solid 1px #eb9d00 !important;
}

textarea:invalid {
  border-bottom: solid 1px #dc3545 !important;
}

textarea:disabled {
  color: black;
}
</style>
