export default {
  methods: {
    mixin_autoResize_resize(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.id = this._uid;
      let e = this.$el.getElementsByTagName('textarea')[0]
      e.setAttribute("style", "height:" + e.scrollHeight + 'px;');
    });
  },
  watch: {
    value: {
      handler: function () {
        this.$nextTick(() => {
          let e = this.$el.getElementsByTagName('textarea')[0]
          e.setAttribute("style", "height:" + e.scrollHeight + 'px;');
        });
      }
    }
  }
}