<template>
  <button class="btn btn-primary pb-2" type="button" :disabled="loading" @click="$emit('click')">
    <span
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
      v-if="loading"
    ></span>
    <span class v-if="loading">Chargement...</span>
    <span class v-if="!loading">
      <div class="d-flex">
        <base-icon :name="icon" class></base-icon>
        <span v-if="text" class="ml-2">{{ text }}</span>
      </div>
    </span>
  </button>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "BaseButton",
  components: { BaseIcon },
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    },
    icon: {
      type: String,
      required: false,
      default: "box"
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
