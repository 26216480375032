<template>
  <div class="mb-2 w-100">
    <div class="rounded p-1 d-flex flex-column shadow-sm" v-background3>
      <div class="d-flex flex-row align-items-center sticky-top" v-background3>
        <slot name="title"></slot>
        <div class="d-flex">
          <!--<button-circle name="trash-2" :color="'#dc3545'" size="18" @click="destroy"></button-circle>
          <button-circle name="save" :color="'#dc3545'" size="18" @click="save"></button-circle>-->
          <button-circle v-if="displayForm" name="arrow-up" size="18" @click="toogle"></button-circle>
          <button-circle v-if="!displayForm" name="arrow-down" size="18" @click="toogle"></button-circle>
        </div>
      </div>
      <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-if="displayForm" class="mt-1">
          <slot name="form"></slot>
        </div>
      </transition>
    </div>
    <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-if="displayForm">
        <slot name="actions"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "ContainerListExtend",
  components: { ButtonCircle },
  data() {
    return {
      displayForm: false
    };
  },
  props: {
    text: {
      type: String,
      required: false
    }
  },
  methods: {
    toogle: function() {
      this.displayForm = !this.displayForm;
      this.$emit("toggle");
    },
    save: function() {
      this.$emit("save");
    },
    destroy: function() {
      this.$emit("destroy");
    },

    /**
     * @param {HTMLElement} element
     */
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }

        element.style.display = null;
      });
    },
    /**
     * @param {HTMLElement} element
     */
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterEnter(element) {
      element.style.height = null;
    },
    /**
     * @param {HTMLElement} element
     */
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    /**
     * @param {HTMLElement} element
     */
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = "0px";
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterLeave(element) {
      element.style.height = null;
    }
  },
  computed: {}
};
</script>
<style>
.enter-active,
.leave-active {
  overflow: hidden;
  -moz-transition: height 0.2s linear;
  -ms-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  -webkit-transition: height 0.2s linear;
  transition: height 0.2s linear;
}
</style>
