<template>
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input w-75"
      value="lightMode"
      :id="id"
      v-bind:checked="checked"
      v-on:change="$emit('change', $event.target.checked)"
      @click.stop
    />
    <label class="custom-control-label" :class="{ dark: darkMode }" :for="id" @click.stop>{{ text }}</label>
  </div>
</template>
<script>
export default {
  name: "BaseCheckbox",
  model: {
    prop: "checked",
    event: "change",
  },
  data() {
    return {
      id: null,
    };
  },
  props: {
    text: {
      type: String,
      required: false,
    },
    checked: {
      type: [Boolean, Number],
      default: false,
    },
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    darkMode: function () {
      return this.$store.getters["colors/darkMode"] ? true : false;
    },
  },
};
</script>
<style lang="css">
.dark {
  color: #ffffff;
}
.dark.custom-control-label::before {
  background-color: #343a40;
}
.dark.custom-control-label::after {
  background-color: #ffffff;
}
</style>
