import axios from "axios"
import Compressor from "compressorjs";

export default {
  data() {
    return {
      mxf_progress: 0,
      mxf_loading: false,
      mxf_files: [], // Array table des fichiers [nom.ext, nom.ext]
      mxf_compressQuality: 1,
      mxf_compressHeight: 500

    };
  },
  methods: {

    _uploadWithAxios(url, file) {

      // Init loading
      this.mxf_loading = true;

      // Form data pour envoyer file
      let formData = new FormData();
      formData.append("file", file);

      // Envoyer via Axios
      axios.post(url, formData, {
        onUploadProgress: e => {
          if (e.lengthComputable) this.mxf_progress = Math.round((e.loaded / e.total) * 100);
        }
      })
        .then(response => this.mxf_files.push(response.data.path))
        .catch(error => console.log(error))
        .finally(() => (this.mxf_loading = false));
    },

    upload(url, compressImage) {
      // Simuler le click sur un input file
      var input = document.createElement("input", { type: "file" });

      // Sur Input
      input.onchange = (e) => {

        // Get file
        const file = e.target.files[0];

        // Compression et redim de l'image
        if (compressImage)
          new Compressor(file, {
            quality: this.mxf_compressQuality,
            height: this.mxf_compressHeight,
            success(result) {
              // Upload
              this._uploadWithAxios(url, result)
              return
            },
          });
        this._uploadWithAxios(url, file)
      };
      input.click();
    },

    convertImageToBase64(callback) {

      // Simuler le click sur un input file
      var input = document.createElement("input");

      // Type File
      input.type = "file"

      // Evènement Sur Input
      input.onchange = (e) => {

        // Obtenir le fichier
        const file = e.target.files[0];

        // Compression et redim de l'image (externe)
        new Compressor(file, {
          quality: this.mxf_compressQuality,
          height: this.mxf_compressHeight,
          success(result) {
            // Convertir en base 64
            const reader = new FileReader();
            let base64DataURL;
            reader.onload = (e) => {
              base64DataURL = e.target.result;
              callback(base64DataURL)
            };
            reader.readAsDataURL(result);
          },
        });
      };
      input.click();
    }
  }
}