<template>
  <div class="mt-3 mb-3" style="line-height:1rem;">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row">
        <router-link v-if="back" :to="backRoute" tag="div">
          <base-icon name="chevron-left" :color="iconColor" width="30" height="30"></base-icon>
        </router-link>
        <div>
          <h4 class="m-0" v-if="title">
            <base-icon
              v-if="showIcon"
              :name="icon"
              :color="iconColor"
              width="26"
              height="26"
              class="mr-2"
            ></base-icon>
            {{title}}
          </h4>
          <span class="text-muted">
            <small>{{subtitle}}</small>
          </span>
        </div>
        <div class="ml-auto d-flex align-items-center">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "BaseToolBar",
  components: { BaseIcon },
  props: {
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    backRoute: {
      type: [Object, Array],
      required: false,
      default: () => {}
    },
    title: {
      type: String,
      required: false,
      default: "Titre"
    },
    subtitle: {
      type: String,
      required: false,
      default: "Sous titre"
    },
    icon: {
      type: String,
      required: false,
      default: "box"
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    iconColor() {
      return this.$store.getters["colors/colors"].colorIconNavBar;
    }
  }
};
</script>
<style>
</style>