<template>
  <div>
    <BaseToolBar :title="'Formation n°' + id" subtitle="Liste des diapos disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une diapo'"
        @click="storeDiapo"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="mb-3">
      <div v-for="diapo in diapos" :key="diapo.id">
        <container-list-extend title="Formation">
          <template v-slot:title>
            <div class="w-100 ml-1">
              <div>
                <span class="mr-1">Diapo n°</span>
                <span class="text-primary cursor-pointer mr-1" @click="decrement_order(diapo)">-</span>
                <span class="mr-1">{{ diapo.order_diapo }}</span>
                <span class="text-primary cursor-pointer mr-1" @click="increment_order(diapo)">+</span>
                <span class="text-muted" v-if="diapo.questions">Questions</span>
              </div>
              <small>
                <div class="une-ligne">{{ diapo.text | striphtml }}</div>
              </small>
            </div>
          </template>
          <template v-slot:form>
            <base-form @click="updateDiapo(diapo)" deleteBtn @delete="deleteDiapo(diapo)" :loading="loading">
              <div class="">
                <div class="d-flex">
                  <div class="d-flex justify-content-center w-100">
                    <label
                      :for="'img_' + diapo.id"
                      class="cursor-pointer w-100 border rounded"
                      v-if="!loadingImage"
                      v-tooltip="'Changer d\'image'"
                    >
                      <div class="btn btn-primary" v-if="!diapo.image">Ajouter une image</div>
                      <input
                        :id="'img_' + diapo.id"
                        type="file"
                        @change="imagechange($event, diapo)"
                        class="d-none"
                        ref="myFile" />

                      <img
                        v-if="diapo.image"
                        :src="'https://api.arkadia-qwark.fr/storage/' + diapo.image"
                        style="max-height: 15rem; max-width: 100%; min-width: 15rem"
                        class=""
                    /></label>
                  </div>
                  <div class="d-flex flex-column ml-3" style="width: 100px">
                    <img
                      style="width: 6rem"
                      src="/images/frm_dispo_1.svg"
                      class="cursor-pointer mr-3"
                      alt=""
                      :class="{ 'border border-primary rounded': diapo.disposition == 1 }"
                      @click="diapo.disposition = 1"
                    />
                    <img
                      style="width: 6rem"
                      src="/images/frm_dispo_2.svg"
                      class="cursor-pointer mr-3"
                      alt=""
                      :class="{ 'border border-primary rounded': diapo.disposition == 2 }"
                      @click="diapo.disposition = 2"
                    />
                    <img
                      style="width: 6rem"
                      src="/images/frm_dispo_3.svg"
                      class="cursor-pointer"
                      alt=""
                      :class="{ 'border border-primary rounded': diapo.disposition == 3 }"
                      @click="diapo.disposition = 3"
                    />
                  </div>
                </div>
                <div class="progress mr-3" style="height: 4px; width: 150px" v-show="progress > 1">
                  <div class="progress-bar" v-bind:style="{ width: progress + '%' }" role="progressbar"></div>
                </div>
                <tiptap class="mt-3" v-model="diapo.text"></tiptap>
              </div>

              <base-form-row row>
                <base-input
                  v-model="diapo.order_diapo"
                  inputText="Position"
                  :errors="feedback.order_diapo"
                ></base-input>
              </base-form-row>
              <base-form-row row>
                <Checkbox
                  class="w-100 pt-3"
                  v-model="diapo.questions"
                  text="Diapo d'évaluation : afficher le questionnaire"
                  :errors="feedback.questions"
                ></Checkbox>
              </base-form-row>
              <div v-if="diapo.questions">
                <base-form-row row>
                  <base-input v-model="diapo.q1" inputText="Question n°1" :errors="feedback.q1"></base-input>
                </base-form-row>
                <base-form-row row>
                  <base-input v-model="diapo.q2" inputText="Question n°2" :errors="feedback.q2"></base-input>
                </base-form-row>
                <base-form-row row>
                  <base-input v-model="diapo.q3" inputText="Question n°3" :errors="feedback.q3"></base-input>
                </base-form-row>
                <base-form-row row>
                  <base-input v-model="diapo.q4" inputText="Question n°4" :errors="feedback.q4"></base-input>
                </base-form-row>
                <base-form-row row>
                  <base-input-area
                    v-model="diapo.correction"
                    text="Correction"
                    :errors="feedback.correction"
                  ></base-input-area>
                </base-form-row>
                <base-form-row row>
                  <Checkbox
                    class="w-100 pt-3"
                    v-model="diapo.r1"
                    text="Réponse n°1 juste"
                    :errors="feedback.r1"
                  ></Checkbox>
                </base-form-row>
                <base-form-row row>
                  <Checkbox
                    class="w-100 pt-3"
                    v-model="diapo.r2"
                    text="Réponse n°2 juste"
                    :errors="feedback.r2"
                  ></Checkbox>
                </base-form-row>
                <base-form-row row>
                  <Checkbox
                    class="w-100 pt-3"
                    v-model="diapo.r3"
                    text="Réponse n°3 juste"
                    :errors="feedback.r3"
                  ></Checkbox>
                </base-form-row>
                <base-form-row row>
                  <Checkbox
                    class="w-100 pt-3"
                    v-model="diapo.r4"
                    text="Réponse n°4 juste"
                    :errors="feedback.r4"
                  ></Checkbox>
                </base-form-row>
              </div>
            </base-form>
          </template>
        </container-list-extend>
      </div>
    </div>
  </div>
</template>
<script>
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import Tiptap from "@/components/bases/Tiptap.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    Checkbox,
    BaseInputArea,
    BaseButton,
    BaseToolBar,
    Tiptap,
  },
  data() {
    return {
      loading: false,
      loadingImage: false,
      feedback: {},
      progress: 0,
      diapos: [],
    };
  },
  props: ["id"],
  computed: {},
  methods: {
    getDiapos: function () {
      this.loading = true;
      this.$http
        .get("/mooc-formation/" + this.id)
        .then((response) => (this.diapos = response.data))
        .finally(() => (this.loading = false));
    },
    storeDiapo: function () {
      this.loading = true;
      this.$http
        .put("/mooc-formations-content", {
          formation_id: this.id,
        })
        .then((resp) => {
          this.diapos.push(resp.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDiapo: function (diapo) {
      this.loading = true;
      this.$http
        .post("/mooc-formations-content", diapo)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    deleteDiapo: function (diapo) {
      this.loading = true;
      this.$http
        .delete("/mooc-formations-content/" + diapo.id)
        .then(() => {
          const index = this.diapos.findIndex((x) => x.id === diapo.id);
          this.diapos.splice(index, 1);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    imagechange: function (e, diapo) {
      this.loadingImage = true;
      this.errors = {};
      this.progress = 0;
      let $_file = e.target.files[0]; // input files
      let formData = new FormData();
      formData.append("file", $_file);
      formData.append("id", diapo.id);

      this.$http
        .post("/mooc-formations-img", formData, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            }
          },
        })
        .then((response) => {
          diapo.image = response.data.path;
          this.progress = 0;
        })
        //.catch((error) => this.errHandler(error))
        .finally(() => (this.loadingImage = false));
    },
    increment_order(diapo) {
      diapo.order_diapo++;
      this.updateDiapo(diapo);
    },

    decrement_order(diapo) {
      diapo.order_diapo--;
      this.updateDiapo(diapo);
    },
  },
  watch: {
    id: function () {
      this.diapos = [];
      this.getDiapos();
    },
  },
  mounted() {
    this.getDiapos();
  },
};
</script>
<style scoped>
.une-ligne {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
